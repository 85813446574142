<template>
  <div class="mx-auto text-center w-full">
    <img
      src="@/assets/img/icons/loader.svg"
      width="50"
      class="py-10"
    >
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LoginAs',
  data() {
    return {
    }
  },
  async mounted() {

    let token = this.$route.params.token
    try {
      await this.$store.dispatch('auth/loginAs', {token: token})
      await this.refresh()

      this.$router.replace({ name: 'Dashboard' })

    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    async refresh() {
      this.loading = true
      try {
        await this.$store.dispatch('auth/refreshUser')
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
      this.loading = false
    },
  }
}
</script>
